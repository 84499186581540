import { initializeApp } from '@entrypoints/app'
import BannerCarousel from '@components/organisms/crousels/BannerCarousel.vue'
import DisclosureContent from '@components/molecules/DisclosureContent.vue'
import SimpleDialog from '@components/molecules/SimpleDialog.vue'
import RegistedDialog from '@components/molecules/homes/RegistedDialog.vue'
import 'swiper/css/bundle'

const app = await initializeApp()
app.component('banner-carousel', BannerCarousel)
app.component('disclosure-content', DisclosureContent)
app.component('simple-dialog', SimpleDialog)
app.component('registed-dialog', RegistedDialog)
app.mount('#app')
